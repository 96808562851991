import {useContext, useState} from 'react';
import {AppContext} from "./Context/AppContext";
import editImage from "./../images/cydeo-edit.svg"
import trashImage from "./../images/cydeo-trash.svg"
import { Link } from "react-router-dom";

export default function AllOrders() {

  const [ data, setData, editData ] = useContext( AppContext );

  const [ selected, setSelected ] = useState([]);


  function handleSelectAll() {
    if(data.Orders.length){
      const temp = []
      for(let i = 0; i < data.Orders.length; i++){
        temp.push(data.Orders[i].id)
      }
      setSelected(temp)
    }
  }

  function handleRemoveSelected(x){
    const temp = []
    for(let i = 0; i < selected.length; i++){
      if(selected[i] !== x){
        temp.push(selected[i])
      }
    }
    setSelected(temp)
  }

  function handleAddSelected(x){
    const temp = []
    for(let i = 0; i < selected.length; i++){
      temp.push(selected[i])
    }
    temp.push(x)
    setSelected(temp)
  }

  function handleDelete(){
    const newOrders= []
    for(let i = 0; i < data.Orders.length; i++){
      if(selected.includes(data.Orders[i].id) === false){
        newOrders.push(data.Orders[i])
      }
    }
    const newArray = {
      Orders: newOrders,
      Products: data.Products
    }

    editData(newArray)
  }

  return <>
    <h1>List of All Orders</h1>
    <div className="__divider"></div>
 
    <div className="orders__wrap">
      <div className="orders__cont">
        <button className="button" onClick={()=>handleSelectAll()}>Select All</button>
        <button className="button" onClick={()=>setSelected([])}>Deselect All</button>
      </div>

      <div className="table-container">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th></th>
            <th><abbr title="Number">#</abbr></th>
            <th>Name</th>
            <th><abbr title="Product">Product</abbr></th>
            <th><abbr title="Date">Date</abbr></th>
            <th><abbr title="Street">Street</abbr></th>
            <th><abbr title="City">City</abbr></th>
            <th><abbr title="State">State</abbr></th>
            <th><abbr title="Zip">ZIP</abbr></th>
            <th><abbr title="Card">Card</abbr></th>
            <th><abbr title="Card Number">Card Number</abbr></th>
            <th><abbr title="Exp">Exp</abbr></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            data.Orders.length ?
              data.Orders.map(d =>
                <tr key={d.id} className={selected.includes(d.id) ? "__selected" : ""}>
                  <th>
                    <button 
                      onClick={
                        selected.includes(d.id) ? ()=> handleRemoveSelected(d.id)
                        : ()=> handleAddSelected(d.id)
                      } 
                      className={selected.includes(d.id) ? "button __select __active" : "button __select"}
                    />
                  </th>
                  <th>{d.quantity}</th>
                  <td>{d.name}</td>
                  <td>{d.product}</td>
                  <td>{d.date}</td>
                  <td>{d.street}</td>
                  <td>{d.city}</td>
                  <td>{d.state}</td>
                  <td>{d.zip}</td>
                  <td>{d.card}</td>
                  <td>{d.cardNo}</td>
                  <td>{d.cardExp}</td>
                  <td>
                    <Link
                      to={`/orders/${d.id}`}
                    >
                      <button className="button __edit"> <img src={editImage} alt="" /> Edit</button>
                    </Link>
                  </td>
                </tr>
              )
            : ""
          }
        </tbody>
      </table>
      </div>

      <div className="orders__cont">
        {
          selected.length ?
          <button className="button __delete" onClick={()=> handleDelete()}> <img src={trashImage} alt="" /> Delete Selected</button>
          :
          <button className="button __delete __inactive"> <img src={trashImage} alt="" /> Delete Selected</button>
        }
      </div>

    </div>
  </>
}
