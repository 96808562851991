import React from 'react';
import './styles/globals.scss';
import { render } from "react-dom";
import {
  BrowserRouter,
} from "react-router-dom";
import App from "./App";
import { AppProvider } from "./Components/Context/AppContext";

const rootElement = document.getElementById("root");

render(
  
  <BrowserRouter>
  <AppProvider>
    <App />
  </AppProvider>
  </BrowserRouter>,
  rootElement
);