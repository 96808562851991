import React from 'react'
import AllProducts from '../Components/AllProducts'
import Nav from '../Components/Global/Nav'

export default function products() {
  return <>
   <Nav current="products" />
   <section className="main__section">
        <div className="main__container">

            <AllProducts/>

        </div>
    </section>
  </>
}
