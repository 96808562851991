import cydeoImage from "./../images/cydeo-logo.svg"
import {useState} from 'react';

export default function Login() {

  const defaultArray = {
    Orders: [
      {
        id: 8,
        quantity: 2,
        name: "Alexandra Gray",
        product: "Screenable",
        date: "04/15/2021",
        street: "7, Miller Street",
        city: "Chicago, IL",
        state: "US",
        zip: 748,
        card: "	VISA",
        cardNo: 321456789012,
        cardExp: "02/24"
      },
      {
        id: 7,
        quantity: 3,
        name: "John Doe",
        product: "Familybea",
        date: "01/08/2021",
        street: "12, Cherry Ave",
        city: "Arlington, VA",
        state: "Canada",
        zip: 76743,
        card: "MasterCard",
        cardNo: 980077987700,
        cardExp: "01/23"
      },
      {
        id: 6,
        quantity: 4,
        name: "Stewart Dawidson",
        product: "Screenable",
        date: "03/29/2021",
        street: "19, Victor Ave",
        city: "Cate Island",
        state: "Canada",
        zip: 24232,
        card: "VISA",
        cardNo: 774555444555,
        cardExp: "03/23"
      },
      {
        id: 5,
        quantity: 3,
        name: "Bart Fisher",
        product: "MoneyCog",
        date: "01/16/2021",
        street: "35, Stone st.",
        city: "McLean, VA",
        state: "US",
        zip: 22043,
        card: "American Express",
        cardNo: 444222333666,
        cardExp: "07/26"
      },
      {
        id: 4,
        quantity: 5,
        name: "Ned Stark",
        product: "MoneyCog",
        date: "	05/12/2021",
        street: "17, Rose Street",
        city: "Newcastle",
        state: "Italy",
        zip: 21444,
        card: "MasterCard",
        cardNo: 777888777888,
        cardExp: "04/25"
      },
      {
        id: 3,
        quantity: 2,
        name: "Bob Martin",
        product: "Familybea",
        date: "12/31/2021",
        street: "22, West Ave",
        city: "New York, NY",
        state: "US",
        zip: 11368,
        card: "VISA",
        cardNo: 333222111222,
        cardExp: "06/24"
      },
      {
        id: 2,
        quantity: 3,
        name: "Samuel Jackson",
        product: "MoneyCog",
        date: "12/21/2021",
        street: "13, Owl st.",
        city: "Starberry, UT",
        state: "US",
        zip: 53665,
        card: "MasterCard",
        cardNo: 555743242342,
        cardExp: "03/25"
      },
      {
        id: 1,
        quantity: 4,
        name: "Robert Baratheon",
        product: "Familybea",
        date: "12/04/2021",
        street: "29, Sanderson Ave",
        city: "Bluecity, CA",
        state: "US",
        zip: 63325,
        card: "MasterCard",
        cardNo: 776565770000,
        cardExp: "03/26"
      }
    ],
    Products: [
      {
        name: "MoneyCog",
        price: 100,
        discount: 0.08
      },
      {
        name: "Familybea",
        price: 80,
        discount: 0.15
      },
      {
        name: "Screenable",
        price: 20,
        discount: 0.10
      }
    ],

  }

  const initialState = {
    username: '',
    password: ''
  }

  const [ input, setInput ] = useState( initialState );
  const [ error, setError ] = useState( false );

  const handleFormSubmit = (e) => {
    e.preventDefault()
    if(input.password === "Tester" && input.username === "Test"){
        localStorage.setItem("token", "cGFzc3dvcmQxMjM=")
        localStorage.setItem("dataMain", JSON.stringify(defaultArray))
        window.location.reload(false);
    } else {
      setError(true)
    }
    
  }

  if(error === true){
		setTimeout(function () {
      setError(false)
    }, 5000);
	}

  const handleOnChange = ( event ) => {
    const newState = { ...input, [event.target.name]: event.target.value };
    setInput( newState );
  };


  return (
    <div className="login__wrap login__uni">
        <div className="login__side">
            <div className="login__cont">
                <img className="login__image" src={cydeoImage} alt=""/>
                <h1>Web Tables Application</h1>
                <form className="login__form" onSubmit={handleFormSubmit}>
                    <div className="login__item field">
                        <label className="label form__label">Username</label>
                        <input type="text" className="input" name="username" onChange={ handleOnChange }/>
                    </div>
                    <div className="login__item field">
                      <label className="label form__label">Password</label>
                      <input type="password" className="input" name="password" onChange={ handleOnChange }/>
                      <div className="form__text"><p>Login with Username: Test , Password: Tester</p></div>
                    </div>
                    {
                      error === true ?
                      <div className="login__error">
                        <p>Incorrect Username or Password, please double check the provided credentials.</p>
                      </div>
                      : ""
                    }
                    <button type="submit" className="btn btn-primary login__submit">Login</button>
                  </form>
            </div>
        </div>
    </div>
  )
}
