import React, { useState, useEffect } from 'react';

export const AppContext = React.createContext( [
    {},
    () => {}
] );

export const AppProvider = ( props ) => {

    const [ data, setData ] = useState(null);

    useEffect( () => {
        let mainData = localStorage.getItem( 'dataMain' );
        mainData = null !== mainData ? JSON.parse( mainData ) : '';
        setData( mainData );
    }, [] );

    function editData(x){
        localStorage.setItem("dataMain", JSON.stringify(x))
        setData(x)
    }

    return (
        <AppContext.Provider value={[ data, setData, editData ]}>
            { props.children}
        </AppContext.Provider>
    )
}