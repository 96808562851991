import React, { useState } from 'react'
import { Link } from "react-router-dom";
import cydeoImage from '../../images/cydeo-logo.svg'

export default function Nav(props) {

  const current = props.current

  const [navButton, setNavButton] = useState(false)

  function handleLogout() {
    localStorage.removeItem("token")
    localStorage.removeItem("dataMain")
    window.location.reload(false);
  }

  return (
    <nav className="nav__wrap">
        <div className="nav__image">
          <img src={cydeoImage} alt="" />
          <h2>Web Table App</h2>
          <button className="__hamburger" onClick={navButton === false ? ()=> setNavButton(true) : ()=> setNavButton(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-4.5 14c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5zm4.5 0c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5zm4.5 0c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5z"/></svg>
          </button>
        </div>
        <div className={navButton === false ? "nav__items__wrap" : "nav__items__wrap __active"}>
          <Link to="/orders">
            <button className={current === "orders" ? "button nav__item active" : "button nav__item"}>
              View all orders
            </button>
          </Link>
          <Link to="/products">
            <button className={current === "products" ? "button nav__item active" : "button nav__item"}>
              View all products
            </button>
          </Link>
          <Link to="/create-order">
            <button className={current === "create" ? "button nav__item active" : "button nav__item"}>
              Order
            </button>
          </Link>
        </div>
        <div className={navButton === false ? "nav__logout" : "nav__logout __active"}>
          <button className="button __logout" onClick={()=> handleLogout()}>
            Logout
          </button>
        </div>
        
    </nav>
  )
}
