import React from 'react'
import Nav from '../Components/Global/Nav'
import MakeOrder from '../Components/MakeOrder'

export default function create() {
  return <>
   <Nav current="create" />
   <section className="main__section">
        <div className="main__container">

            <MakeOrder/>

        </div>
    </section>
  </>
}
