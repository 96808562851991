import {useContext, useState} from 'react';
import {AppContext} from "./Context/AppContext";
import {useNavigate} from "react-router-dom";

export default function MakeOrder() {

    const [ data, setData, editData ] = useContext( AppContext );
    const navigate = useNavigate();

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const todayComplete = dd + '/' + mm + '/' + yyyy;

    
    const initialState = {
        card: '',
        cardExp: '',
        cardNo: '',
        city: '',
        date: todayComplete,
        id: '',
        name: '',
        product: 'MoneyCog',
        quantity: 1,
        state: '',
        street: '',
        zip: ''
    }

    const [ input, setInput ] = useState( initialState );
    const [ value, setValue ] = useState( 0 );
    const [ errorCard, setErrorCard ] = useState(false);
    const [ errorExp, setErrorExp ] = useState(false);


    function fixedValues() {
        for(let i = 0; i < data.Products.length; i++){
            if(data.Products[i].name === input.product){
                return {price: data.Products[i].price, viewDis: data.Products[i].discount * 100}
            }
        }
    }

    function handleCalc(){
        const returned = (input.quantity * fixedValues().price) * ((100 - fixedValues().viewDis) / 100)
        setValue(returned.toFixed(2))
    }

    const handleOnChange = ( event ) => {
        const newState = { ...input, [event.target.name]: event.target.value };
        setInput( newState );
	};

    const handleFormSubmit = ( event ) => {
		event.preventDefault();
        const newOrders= []
        var idNumbers=[]
        var largest = 0
        for(let x = 0; x < data.Orders.length; x++){
            idNumbers.push(data.Orders[x].id)
        }
        for (var y = 0; y < idNumbers.length; y++) {
            if (largest < idNumbers[y] ) {
                largest = idNumbers[y];
            }
        }
        const editedOrder = {
            card: input.card,
            cardExp: input.cardExp,
            cardNo: parseInt(input.cardNo),
            city: input.city,
            date: input.date,
            id: largest + 1,
            name: input.name,
            product: input.product,
            quantity: parseInt(input.quantity),
            state: input.state,
            street: input.street,
            zip: parseInt(input.zip)
        }
        newOrders.push(editedOrder)
        for(let i = 0; i < data.Orders.length; i++){
            newOrders.push(data.Orders[i])
        }
       
        const newArray = {
            Orders: newOrders,
            Products: data.Products
        }

        
        // IS EXPIRY DATE VALID
        let isExpValid
        const expDateSplit = editedOrder.cardExp.split('')
        if( expDateSplit.length === 5 ){
            if(expDateSplit[2] === "/"){
                const checks = []
                if(typeof parseInt(expDateSplit[0]) === 'integer'){
                    checks.push("fail")
                }
                if(typeof parseInt(expDateSplit[1]) === 'integer'){
                    checks.push("fail")
                }
                if(typeof parseInt(expDateSplit[3]) === 'integer'){
                    checks.push("fail")
                } 
                if(typeof parseInt(expDateSplit[4]) === 'integer'){
                    checks.push("fail")
                }

                if(checks.length !== 0){
                    isExpValid = false
                } else {
                    isExpValid = true
                }
            } else{
                isExpValid = false
            }
        } else{
            isExpValid = false
        }

        // Handle Errors

        if(editedOrder.cardNo.toString().split('').length < 12){
            setErrorCard(true)
        } else if( isExpValid === false ){
            setErrorExp(true)
            setErrorCard(false)
        } else{
            setErrorExp(false)
            setErrorCard(false)
            editData(newArray)
            navigate('/orders', { replace: true })
        }
		
	};

    function handleReset(){
        window.location.reload(false);
    }

        

  return <>
    <h1>Make an Order</h1>
    <div className="__divider"></div>

    <form onSubmit={ handleFormSubmit }>
        <div className="make__wrap">
            <div className="columns">
                <div className="column is-4">
                <h2>Product Information</h2>

                <div className="field">
                    <label className="label">Product *</label>
                    <div className="control">
                    <div className="select">
                        <select onChange={ handleOnChange } value={input.product} name="product">
                        <option value="MoneyCog">MoneyCog</option>
                        <option value="Familybea">Familybea</option>
                        <option value="Screenable">Screenable</option>
                        </select>
                    </div>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Quantity *</label>
                    <div className="control">
                    <input className="input" type="number" placeholder="Enter amount desired" onChange={ handleOnChange } value={input.quantity} name="quantity" required/>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Price per unit</label>
                    <div className="control">
                    <input className="input" type="number" placeholder="Enter the price for an individual unit" value={fixedValues().price} readOnly/>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Discount</label>
                    <div className="control __uni">
                    <input className="input" type="number" placeholder="Enter the discount rate e.g. '80' " value={fixedValues().viewDis} readOnly/>
                    <h3>%</h3>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Total</label>
                    <div className="control __uni">
                    <input className="input" type="number" value={value} readOnly/>
                    <button className="button" type="button" onClick={()=> handleCalc()}>Calculate</button>
                    </div>
                </div>
                </div>

                <div className="column is-4">
                <h2>Address Information</h2>

                <div className="field">
                    <label className="label">Customer Name *</label>
                    <div className="control">
                    <input className="input" type="text" placeholder="Full name of the customer" onChange={ handleOnChange } name="name" value={input.name} required/>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Street *</label>
                    <div className="control">
                    <input className="input" type="text" placeholder="Street address of the customer" onChange={ handleOnChange } name="street" value={input.street} required/>
                    </div>
                </div>

                <div className="field">
                    <label className="label">City *</label>
                    <div className="control">
                    <input className="input" type="text" placeholder="City where the customer lives" onChange={ handleOnChange } name="city" value={input.city} required/>
                    </div>
                </div>

                <div className="field">
                    <label className="label">State</label>
                    <div className="control">
                    <input className="input" type="text" placeholder="State where the customer lives" onChange={ handleOnChange } name="state" value={input.state}/>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Zip</label>
                    <div className="control">
                    <input className="input" type="text" placeholder="The cusomer's zip code" onChange={ handleOnChange } name="zip" value={input.zip}/>
                    </div>
                </div>
                </div>

                <div className="column is-4">
                <h2>Payment Information</h2>

                <div className="field">
                    <label className="label">Card *</label>
                    <div className="control">
                    <label className="radio">
                        <input type="radio" name="card" value="Visa" onChange={ handleOnChange } required/>
                        Visa
                    </label>
                    <label className="radio">
                        <input type="radio" name="card" value="MasterCard" onChange={ handleOnChange } required/>
                        MasterCard
                    </label>
                    <label className="radio">
                        <input type="radio" name="card" value="American Express" onChange={ handleOnChange } required/>
                        American Express
                    </label>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Card No. *</label>
                    <div className="control">
                    <input className="input" type="text" placeholder="The number on the card" onChange={ handleOnChange } name="cardNo" value={input.cardNo} required/>
                    </div>
                </div>

                {
                    errorCard === true ?
                    <div className="field__error">
                        <p>Please enter at least 12 digits.</p>
                    </div>
                    : ""
                }

                <div className="field">
                    <label className="label">Expiry Date (mm/yy) *</label>
                    <div className="control">
                    <input className="input" type="text" placeholder="e.g. 04/24" onChange={ handleOnChange } name="cardExp" value={input.cardExp} required/>
                    </div>
                </div>

                {
                    errorExp === true ?
                    <div className="field__error">
                        <p>Please enter the date in this format "mm/yy" where mm and yy are numbers seperated by a "/".</p>
                    </div>
                    : ""
                }

                </div>

            </div>

            <div className="make__cont">
                <button className="button" type="submit">Process Order</button>
                <button className="button" onClick={()=> handleReset()}>Reset Fields</button>
            </div>

            </div>
        </form>

  </>
}
