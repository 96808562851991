import React, { useState, useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./routes/login";
import Orders from "./routes/orders";
import Order from "./routes/order";
import Products from "./routes/products";
import CreateOrder from "./routes/create-order";

function App() {

  const [isLogged, setIsLogged] = useState(undefined);

  function handleLogged(x) {
    if(x === false){
      setIsLogged(false)
    } else {
      setIsLogged(true)
    }
  }

  useEffect(() => {
    if(localStorage.getItem("token") === "cGFzc3dvcmQxMjM="){
      handleLogged(true)
    } else {
      handleLogged(false)
    }
  }, [])

  

  return (
    undefined !== isLogged ?
    <Routes>
      <Route path="/" element={isLogged === true ? <Navigate to="/orders" /> : <Navigate to="/login" />} />
      <Route path="/login" element={isLogged === true ? <Navigate to="/orders" /> : <Login/>}/>
      <Route path="/orders" element={isLogged === false ? <Navigate to="/login" /> : <Orders />} />
      <Route path="/orders/:orderId" element={isLogged === false ? <Navigate to="/login" /> : <Order />} />
      <Route path="/products" element={isLogged === false ? <Navigate to="/login" /> : <Products />} />
      <Route path="/create-order" element={isLogged === false ? <Navigate to="/login" /> : <CreateOrder />} />
    </Routes>
    : ""
  );
}

export default App;
