import React from 'react'
import AllOrders from '../Components/AllOrders'
import Nav from '../Components/Global/Nav'

export default function orders() {
  return <>
   <Nav current="orders" />
   <section className="main__section">
        <div className="main__container">

            <AllOrders/>

        </div>
    </section>
  </>
}
