import React from 'react'

export default function AllProducts() {
  return <>
        <h1>List of Products</h1>
        <div className="__divider"></div>

        <div className="orders__wrap">
        <table className="table is-fullwidth">
            <thead>
            <tr>
                <th>Product name</th>
                <th>Price</th>
                <th>Discount</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>MoneyCog</th>
                <td>$100</td>
                <td>8%</td>
            </tr>
            <tr>
                <th>Familybea</th>
                <td>$80</td>
                <td>15%</td>
            </tr>
            <tr>
                <th>Screenable</th>
                <td>$20</td>
                <td>10%</td>
            </tr>
            </tbody>
        </table>
        </div>
  </>
}
